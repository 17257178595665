import React from 'react';
import { Link } from "react-router-dom";
import logoSolo from '../../assets/img/logo-izika-solo.png';
import logoTeam from '../../assets/img/logo-izika-team.png';

export default function ChooseProduct() {
    return (
      <main className="h-full flex flex-col flex-1">

        <div className="h-full flex flex-col md:flex-row flex-1">
          <div className="flex justify-center flex-col items-center flex-1 p-5 bg-gradient-to-tr from-brown-B50 to-brown-B100">
            <h1 className="font-heading flex flex-col items-center">
              <span className="text-lg uppercase"><img src={logoSolo} alt="logo izika" className="w-40" /></span>
            </h1>
            <div className="mx-auto my-8 max-w-md text-md md:max-w-3xl text-center">
              <p className="mb-1 max-w-md">
                Vous êtes <strong>indépendant</strong> ou <strong>TPE</strong>{', '}
                vous voulez une solution simple et rapide de <strong>calcul des IK</strong>.
              </p>
            </div>

            <div className="sm:flex sm:justify-center flex-col items-center">
              <Link to="solo" className="rounded-md bg-brown-B600 font-heading rounded-full px-4 py-2">
                Je crée mon compte <strong>Solo</strong>
              </Link>
              <Link to="/sign-in" className="text-xs mt-1 underline">J'ai déjà un compte</Link>
            </div>
          </div>
          <div className="flex justify-center flex-col items-center flex-1 p-5 bg-gradient-to-tr from-yellow-Y50 to-yellow-Y40">
            <h1 className="font-heading flex flex-col items-center">
              <span className="text-lg uppercase"><img src={logoTeam} alt="logo izika team" className="w-40" /></span>
            </h1>
            <div className="mx-auto my-8 max-w-md text-md md:max-w-3xl text-center">
              <p className="mb-1 max-w-md">
                Vous êtes une <strong>entreprise</strong> ou une <strong>administration</strong>{', '}
                vous cherchez <em>en plus</em> une <strong>gestion des flux d'approbation des IK</strong>.
              </p>
            </div>
            <div className="sm:flex sm:justify-center flex-col items-center">
              <Link to="team" className="rounded-md bg-yellow-Y100 font-heading rounded-full px-4 py-2">Je crée un compte <strong>team</strong></Link>
              <Link to="/sign-in" className="text-xs mt-1 underline">J'ai déjà un compte</Link>
            </div>

          </div>
        </div>

        <div className="flex justify-center  bg-brown-B900 px-6 py-2.5 sm:px-3.5 ">
          <p className="text-sm leading-6 text-white">
              Plus d'infos sur nos offres ?
              <svg viewBox="0 0 2 2" className="mx-1 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                <circle cx={1} cy={1} r={1} />
              </svg>
              <a href="https://izika.com" className="whitespace-nowrap font-semibold">
                Retrouvez tous les détails sur izika.com&nbsp;<span aria-hidden="true">&rarr;</span>
              </a>
          </p>
        </div>

        <div></div>

      </main>
    );
}
