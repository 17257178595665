import { InMemoryCache, makeVar } from "@apollo/client";

const authInitialValues = {
  isAuthenticated: true,
};

export const authVar = makeVar(authInitialValues);

export const apolloCache = new InMemoryCache({
  // possibleTypes: {
  //   Onboarding: ['TeamOnboarding'],
  // },
  typePolicies: {
    Query: {
      fields: {
        auth: {
          read() {
            return authVar();
          },
        },
      },
    },
    TeamOnboarding: {
      keyFields: ['memberId'],
    }
  },
});
