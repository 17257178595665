import React from 'react';
import { useForm } from "react-hook-form";
import { useCheckUsernameExists } from "../../../shared/useCases/useCheckEmailExists";
import { MainTitle } from "../../../shared/components/layout";
import SubmitButton from "../../../shared/components/form/SubmitButton";
import { useSignUp } from "../../../shared/useCases/useSignUp";
import logoGoogle from "../../../assets/img/ico-google.png";
import logoSolo from "../../../assets/img/logo-izika-solo.png";
import illuCoffee from "../../../assets/img/illu-coffee_t_logo.png";
import { Link } from "react-router-dom";

export default function SignUp() {
  const { signUp } = useSignUp();

  const { handleSubmit, register, formState: { errors, isSubmitting } } = useForm();
  const [checkUsernameExists] = useCheckUsernameExists();

  const onSubmit = async (values) => {
    await signUp(values.email, values.password, 'solo');

    return window.location.replace('/app');
  };

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col">
        <div className="flex flex-1">
          <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24 bg-brown-B50">
            <div className="mx-auto w-full max-w-sm lg:w-96">
              <div>
                <img
                  className="h-16 w-auto mb-2 lg:hidden"
                  src={logoSolo}
                  alt="Your Company"
                />
                <MainTitle>Créez votre compte</MainTitle>
                <p className="mt-1 text-sm">
                  Vous avez déjà un compte ?{' '}
                  <Link to="/sign-in" className="font-medium text-brown-B700 hover:text-yellow-Y400">
                    connectez-vous
                  </Link>
                </p>
              </div>

              <div className="mt-8">

                <div className="mt-6">
                  <form noValidate="novalidate" onSubmit={handleSubmit(onSubmit)} className="space-y-6" action="#" method="POST">
                    <div>
                      <label htmlFor="email" className="block text-sm font-medium">
                        Adresse E-mail
                      </label>
                      <div className="mt-1">
                        <input
                          {...register('email',{
                            required: true,
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            },
                            validate: {
                              notExists: async (value) => {
                                const response = await checkUsernameExists(value);

                                return !response.data.data.found;
                              }
                            }
                          })}
                          id="email"
                          name="email"
                          type="email"
                          placeholder="Adresse e-mail"
                          autoComplete="email"
                          autoFocus
                          required
                          disabled={isSubmitting}
                          className="block w-full appearance-none rounded-md border border-brown-B500 px-3 py-2 placeholder-neutrals-N500 shadow-sm focus:border-yellow-Y100 focus:outline-none focus:ring-yellow-Y60 sm:text-sm"
                        />
                        {errors.email && (
                          <div className="text-sm text-orange-O500">
                            {errors.email.type === "required" && "L'adresse e-mail est obligatoire"}
                            {errors.email.type === "pattern" && "Cette adresse e-mail ne semble pas valide"}
                            {errors.email.type === "notExists" && "Cette adresse email est déjà utilisée"}
                          </div>
                        )}

                      </div>
                    </div>

                    <div>
                      <label htmlFor="password" className="block text-sm font-medium">
                        Mot de passe
                      </label>
                      <div className="mt-1">
                        <input
                          {...register('password',{ required: true, minLength: 8, })}
                          id="password"
                          name="password"
                          type="password"
                          autoComplete="current-password"
                          placeholder="Mot de passe"
                          disabled={isSubmitting}
                          required
                          className="block w-full appearance-none rounded-md border border-brown-B500 px-3 py-2 placeholder-neutrals-N500 shadow-sm focus:border-yellow-Y100 focus:outline-none focus:ring-yellow-Y60 sm:text-sm"
                        />
                        {errors.password && (
                          <div className="text-sm text-orange-O500">
                            {errors.password.type === "required" && "Le mot de passe est obligatoire"}
                            {errors.password.type === "minLength" && "Le mot de passe doit contenir au moins 8 caractères"}
                          </div>
                        )}
                      </div>
                    </div>

                    <div>
                      <SubmitButton loading={isSubmitting} theme="solo">
                        Continuer
                      </SubmitButton>
                    </div>
                  </form>
                </div>
                  <div className="mt-6">
                    <div className="relative">
                      <div className="absolute inset-0 flex items-center">
                        <div className="w-full border-t border-brown-B500" />
                      </div>
                      <div className="relative flex justify-center text-sm">
                        <span className="bg-brown-B50 px-2 text-brown-B600">Ou </span>
                      </div>
                    </div>

                    <div className="mt-6">
                      <a
                        href="/connect/google?state=eyJwcm9kdWN0IjoidGVhbSJ9"
                        className="inline-flex items-center w-full justify-center rounded-md border border-brown-B500 gap-1 bg-white py-2 px-4 text-sm font-medium text-brown-B600 shadow-sm hover:bg-gray-50"
                      >
                        <img src={logoGoogle} width="24" />
                        <span >S'inscrire avec mon compte Google</span>
                      </a>
                    </div>
                  </div>
              </div>
            </div>
          </div>
          <div className="relative hidden w-0 flex-1 lg:block bg-gradient-to-tr from-brown-B100 to-brown-B600">
            <img
              className="absolute inset-0 h-full w-full object-contain max-w-lg bg-center mx-auto"
              src={illuCoffee}
              alt=""
            />
          </div>
        </div>
        <div className="flex items-center justify-center py-4 drop-shadow-[0_-3px_3px_rgba(171,158,120,0.25)] bg-[#ffffff]">
         {/* <div className="min-w-0 flex-1 flex items-center">
            <img
              className="h-10 w-auto"
              src={logoSolo}
              alt="Your Company"
            />
            <p className="text-sm ml-8 hidden lg:block">
              La solution de calcul d'IK
              pour les <span className="text-brown-B700 font-medium">indépendants</span>,
              les <span className="text-brown-B700 font-medium">auto-entrepreneurs</span>,
              les <span className="text-brown-B700 font-medium">professions libérales</span>,
              les <span className="text-brown-B700 font-medium">TPE</span>...
            </p>
          </div>*/}

            <p className="text-sm">Pour créer un compte <span className="text-yellow-Y200 font-medium">entreprise</span>, c'est par ici !</p>
            <Link to="/join/team" className="ml-3 inline-flex items-center rounded-md bg-yellow-Y100 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-yellow-Y200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-Y100">
              Izika Team
            </Link>
        </div>
      </div>
    </>
  );
}
