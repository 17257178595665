import React from 'react';
import { Trans } from "react-i18next/icu.macro";
import {
  MainContainer,
  MainHeadingSection,
  MainTitle,
  MainText,
} from "../../../shared/components/layout";
import clsx from "clsx";
import { ensureUserIsAuthenticatedAndOnboardingIsNotCompleted } from "../Team.page";

export async function loader(args, context) {
  await ensureUserIsAuthenticatedAndOnboardingIsNotCompleted(context, false);

  return null;
}

export default function WhatNow() {
  return (
    <MainContainer>
      <MainHeadingSection>
        <MainTitle>C'est fini !</MainTitle>
        <MainText>
          On ne vous embête pas plus pour le moment ;<br />
          Maintenant, à vous de jouer !
        </MainText>
      </MainHeadingSection>

      <div className="gap-2 flex flex-col">
        <a href={window.ENV.MANAGER_URL} className={clsx(
          "flex w-full justify-center py-2 px-4",
          "rounded-md bg-yellow-Y100",
          "text-sm font-medium text-white shadow-sm",
          "hover:bg-yellow-Y200",
          "focus:outline-none focus:ring-2 focus:ring-yellow-Y60 focus:ring-offset-2")}>
          <Trans i18nKey="team_what_now_go_to_manager">
            Accéder au manager
          </Trans>
        </a>
        <a href={window.ENV.APP_URL} className={clsx(
          "flex w-full justify-center py-2 px-4",
          "rounded-md",
          "text-sm font-medium text-brown-B700 border-brown-B700 border-2 shadow-sm",
          "hover:bg-brown-B700 hover:text-white",
          "focus:outline-none focus:ring-2 focus:ring-brown-B700 focus:ring-offset-2")}>
          <Trans i18nKey="team_what_now_go_to_app">
            Accéder à la gestion des IK utilisateur
          </Trans>
        </a>
      </div>
    </MainContainer>
  );
}
