import { gql, useMutation } from "@apollo/client";

export const SUBMIT_TEAM_ONBOARDING = gql`
  mutation SubmitTeamOnboarding(
    $companyName: String,
    $fleetSize: Int,
    $usage: [String!],
    $firstName: String,
    $lastName: String,
    $phoneNumber: String,
    $accountingClosureMonth: Int,
    $customRates: [CustomMileageAllowanceRateInput!],
    $invitations: [String!],
    $complete: Boolean!
  ) {
    submitTeamOnboarding(
      companyName: $companyName,
      fleetSize: $fleetSize,
      usage: $usage,
      firstName: $firstName,
      lastName: $lastName,
      phoneNumber: $phoneNumber,
      accountingClosureMonth: $accountingClosureMonth,
      customRates: $customRates,
      invitations: $invitations,
      complete: $complete,
    ) {
      memberId
      companyName
      fleetSize
      usage
      firstName
      lastName
      phoneNumber
      accountingClosureMonth
      customRates {
        label
        index
        bonus
      }
      invitations
    }
  }
`

export function useSubmitTeamOnboarding() {
  const [mutate, {loading, data, error}] = useMutation(
    SUBMIT_TEAM_ONBOARDING,
    // {
    //   update(cache, { data: { submitTeamOnboardingCompany } }) {
    //     cache.modify({
    //       fields: {
    //         onboarding(existingOnboarding = {}) {
    //           console.log(existingOnboarding);
    //           console.log(submitTeamOnboardingCompany);
    //           const newOnboardingRef = cache.writeFragment({
    //             data: submitTeamOnboardingCompany,
    //             fragment: gql`
    //               fragment NewCompany on TeamOnboarding {
    //                 companyName
    //                 fleetSize
    //                 roleInCompany {
    //                   service
    //                   role
    //                 }
    //               }
    //             `
    //           });
    //
    //           // return {
    //           //   ...existingOnboarding
    //           // };
    //           return {
    //             ...existingOnboarding,
    //             ...newOnboardingRef
    //           };
    //         }
    //       }
    //     });
    //   }
    // }
  );

  return {
    loading,
    data,
    error,
    submitTeamOnboarding: (
      companyName,
      fleetSize,
      usage,
      firstName,
      lastName,
      phoneNumber,
      accountingClosureMonth,
      customRates,
      invitations,
      complete,
    ) => mutate({
      variables: {
        companyName,
        fleetSize,
        usage,
        firstName,
        lastName,
        phoneNumber,
        accountingClosureMonth,
        customRates,
        invitations,
        complete,
      }
    }),
  };
}
