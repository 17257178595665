import React, { useEffect } from 'react';
import { Link, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { MainTitle } from "../../shared/components/layout";
import logoGoogle from "../../assets/img/ico-google.png";
import logoColor from "../../assets/img/logo-izika-color_x400.png";
import { XCircleIcon } from "@heroicons/react/20/solid";
import { authenticate } from "../../shared/useCases/useAuthenticate";
import SubmitButton from "../../shared/components/form/SubmitButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/pro-light-svg-icons";

const TARGET_PATH_PARAM_NAME = '_target_path';

export default function SignIn() {
  let [searchParams] = useSearchParams();
  const {
    handleSubmit,
    register,
    setError,
    reset,
    setFocus,
    formState: { errors, isSubmitting },
  } = useForm();

  const oauthNotFound = searchParams.get('oauthNotFound');
  const showPasswordResetMessage = searchParams.has('rp');
  const email = searchParams.get('email');

  useEffect(() => {
    if (email) {
      reset({
        email
      });
    }
  }, [email, setFocus, reset]);

  const onSubmit = async (values) => {
    const result = await authenticate(values.email, values.password, searchParams.get(TARGET_PATH_PARAM_NAME));

    if (result.error) {
      setError('root.serverError', {
        type: 'server',
      });

      return;
    }

    if (result.redirectTo) {
      return window.location.replace(result.redirectTo);
    }
  };

  return (
    <>
      <div className={`
        bg-gradient-to-br from-white to-yellow-Y50
         flex min-h-full flex-1 flex-col justify-center pt-6 lg:pt-8 pb-12 sm:px-6 lg:px-8
     `}>

        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-12 w-auto"
            src={logoColor}
            alt="Izika"
          />

          <MainTitle className="text-center">Connectez-vous à votre compte Izika</MainTitle>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
          <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
            <div className="">

              {errors.root?.serverError?.type === 'server' && (
                <div className="rounded-md bg-brown-B50 p-4 mb-3">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <XCircleIcon className="h-5 w-5 text-red-R500" aria-hidden="true" />
                    </div>
                    <div className="ml-3">
                      <h3 className="text-sm font-medium text-red-800">
                        Nous n'avons pas trouvé de compte avec ces identifiants.
                        Réessayez en vérifiant votre e-mail et mot de passe, ou <Link to="/join" className="underline">créez un compte</Link>.
                      </h3>
                    </div>
                  </div>
                </div>
              )}

              {oauthNotFound && (
                <div className="rounded-md bg-brown-B50 p-4 mb-3">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <XCircleIcon className="h-5 w-5 text-red-R500" aria-hidden="true" />
                    </div>
                    <div className="ml-3">
                      <h3 className="text-sm font-medium text-red-800">
                        Nous n'avons pas trouvé de compte avec ces identifiants.
                        Réessayez avec un autre e-mail ou <Link to="/join" className="underline">créez un compte</Link>.
                      </h3>
                    </div>
                  </div>
                </div>
              )}

              {showPasswordResetMessage && (
                <div className="rounded-md bg-brown-B50 p-4 mb-3">
                  <div className="flex">
                    <div className="flex-shrink-0 text-green-G500">
                      <FontAwesomeIcon icon={faCheckCircle} size="lg" />
                    </div>
                    <div className="ml-3">
                      <h2 className="text-sm font-medium">Mot de passe réinitialisé</h2>
                      <p className="text-sm">
                        Vous pouvez maintenant vous connecter à votre compte avec votre nouveau mot de passe
                      </p>
                    </div>
                  </div>
                </div>
              )}

              <form noValidate="novalidate" onSubmit={handleSubmit(onSubmit)} className="space-y-6" action="#" method="POST">
                <div>
                  <label htmlFor="email" className="block text-sm font-medium">
                    Adresse E-mail
                  </label>
                  <div className="mt-1">
                    <input
                      {...register('email',{
                        required: true,
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        },
                      })}
                      id="email"
                      name="email"
                      type="email"
                      placeholder="Adresse e-mail"
                      autoComplete="email"
                      autoFocus={!email}
                      required
                      disabled={isSubmitting}
                      className="block w-full appearance-none rounded-md border border-yellow-Y100 px-3 py-2 placeholder-neutrals-N500 shadow-sm focus:border-yellow-Y100 focus:outline-none focus:ring-offset-2 focus:ring-yellow-Y60 focus:ring-2 sm:text-sm"
                    />
                    {errors.email && (
                      <div className="text-sm text-orange-O500">
                        {errors.email.type === "required" && "L'adresse e-mail est obligatoire"}
                        {errors.email.type === "pattern" && "Cette adresse e-mail ne semble pas valide"}
                      </div>
                    )}

                  </div>
                </div>

                <div>
                  <div className="flex items-center justify-between">
                    <label htmlFor="password" className="block text-sm font-medium">
                      Mot de passe
                    </label>
                    <div className="text-sm">
                      <Link to="/forgot-password" href="#" className="font-semibold text-yellow-Y300 hover:text-yellow-Y400 hover:underline">Mot de passe oublié ?</Link>
                    </div>
                  </div>
                  <div className="mt-1">
                    <input
                      {...register('password',{ required: true })}
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      autoFocus={email}
                      placeholder="Mot de passe"
                      disabled={isSubmitting}
                      required
                      className="block w-full appearance-none rounded-md border border-yellow-Y100 px-3 py-2 placeholder-neutrals-N500 shadow-sm focus:border-yellow-Y100 focus:outline-none focus:ring-offset-2 focus:ring-yellow-Y60 focus:ring-2 sm:text-sm"
                    />
                    {errors.password && (
                      <div className="text-sm text-orange-O500">
                        {errors.password.type === "required" && "Le mot de passe est obligatoire"}
                      </div>
                    )}
                  </div>
                </div>

                <div>
                  <SubmitButton loading={isSubmitting} theme="team">
                    Me connecter
                  </SubmitButton>
                </div>
              </form>
            </div>

            <div className="mt-6">
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-brown-B200" />
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="bg-white px-2 text-brown-B500">Ou </span>
                </div>
              </div>

              <div className="mt-6">
                <a
                  href="/connect/google"
                  className="inline-flex items-center w-full justify-center rounded-md border border-brown-B200 gap-1 bg-white py-2 px-4 text-sm font-medium text-brown-B900 shadow-sm hover:bg-gray-50"
                >
                  <img src={logoGoogle} width="24" />
                  <span >Me connecter avec mon compte Google</span>
                </a>
              </div>
            </div>
          </div>

          <p className="mt-10 text-center text-sm text-gray-500">
            Pas encore de compte ?{' '}
            <Link to="/join" className="font-semibold leading-6 underline">
              Créer un compte
            </Link>
          </p>
        </div>
      </div>
    </>
  );
}
